import * as React from "react"
import PropTypes from "prop-types"

import "./full-screen-section.css"

const FullScreenSection = ({ id, children }) => {

  return (
    <>
        <section id={id ? id : ''}>{children}</section>
    </>
  )
}

FullScreenSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullScreenSection
